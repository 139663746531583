
.scroll-container {
    height: 100vh; 
    overflow-x: scroll; 
    overflow-y: hidden;
    white-space: nowrap; 
}

.scrolling-image {
    height: 100vh; 
}

.buttonCI path:nth-child(1){
    cursor: pointer;
    transition: fill 0.5s cubic-bezier(0.37, 0, 0.63, 1);
    animation: glow 2s linear infinite alternate-reverse;
}

.buttonCI:hover path:nth-child(1){
    fill: #3F3F3F;
}

@keyframes glow{
    from{
        filter: drop-shadow(0px 0px 10px rgb(90, 90, 90));
    } to {
        filter: drop-shadow(0px 0px 20px rgb(101, 100, 100));
    }
}


//tennis scene - ajout décor si tps
.joueurGauche{
    opacity: 0;
    visibility: hidden;
    transform: rotate(-42deg);
    transform-origin: bottom center;
    transition: 0.5s linear;
    
    &--active{
        opacity: 1;
        visibility: visible;
        transform: rotate(-2deg);
        animation: joueurG 2s cubic-bezier(0.25, 1, 0.5, 1) infinite alternate-reverse;
        animation-delay: 0.5s; 
        animation-iteration-count: 9; 
    }
}
.joueurDroit{
    opacity: 0;
    visibility: hidden;
    transform: rotate(40deg);
    transform-origin: bottom center;
    transition: 0.5s linear;

    &--active{
        opacity: 1;
        visibility: visible;
        transform: rotate(3deg);
        animation: joueurD 2s cubic-bezier(0.5, 0, 0.75, 0) infinite alternate-reverse;
        animation-delay: 0.5s; 
        animation-iteration-count: 9; 
    }
}
.balle{
    opacity: 0;
    visibility: hidden;
    transition: opacity 2s linear;
    transition-delay: 0.5s;
    transform-origin: center 1200px;
    transform:translateY(30px) rotate(17deg);

    &--active{
        transform-origin: center 1200px;
        animation: balle 2s linear infinite alternate-reverse;
        visibility: visible;
        opacity: 1;
        animation-delay: 0.5s; 
        animation-iteration-count: 10; 
    }
}
.filet{
    opacity: 0;
    visibility: hidden;
    transition: 0.5s linear;

    &--active{
        opacity: 1;
        visibility: visible;
    }
}
.fondTennis{
    transform: translateY(0%);
    transition: 1s cubic-bezier(.36,0,.67,-0.37);

    &--remove{
        transform: translateY(105%);
    }
}

//scout scene
.flammes{
    animation: feu 1.5s linear infinite alternate-reverse;
}
.brasD{
    animation: bras 3s infinite linear alternate-reverse;
    transform-origin: 85% 80%;

}
.brasG{
    animation: bras 3s linear infinite alternate-reverse;
    transform-origin: 80% 80%;
}
.tente{
    transform: translateY(100%);
    transition: 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    &--active{
        transform: translateY(0);
    }
}
.feu{
    transform: translateY(100%);
    transition: 1.1s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    &--active{
        transform: translateY(0);
    }
}
.arbres{
    path:nth-child(1){
        transform: rotate(90deg) translateX(110px);
        transform-origin: 90% bottom;
        transition: 1s cubic-bezier(0.33, 1, 0.68, 1);
        transition-delay: 0.5s;
    }
    path:nth-child(2){
        transform: rotate(-90deg) translateX(-90px);
        transform-origin: 75% bottom;
        transition: 1s cubic-bezier(0.33, 1, 0.68, 1);
        transition-delay: 0.5s;
    }
    path:nth-child(3){
        transform: rotate(-90deg) translateX(-100px);
        transform-origin: 77% bottom;
        transition: 1s cubic-bezier(0.33, 1, 0.68, 1);
        transition-delay: 0.5s;
    }

    &--active{
        path:nth-child(1){
            transform: rotate(0deg) translateX(0);
        }
        path:nth-child(2){
            transform: rotate(0deg) translateX(0);
        }
        path:nth-child(3){
            transform: rotate(0deg) translateX(0);
        }
    }
}
.persoDroite{
    transform: rotate(90deg) translateX(60px);
    transform-origin: 85% bottom;
    transition: 0.8s cubic-bezier(0.33, 1, 0.68, 1);
    transition-delay: 0.7s;

    &--active{
        transform: rotate(0deg) translateX(0px);
    }
}
.persoGauche{
    transform: rotate(-90deg) translateX(-60px);
    transform-origin: 80% bottom;
    transition: 0.8s cubic-bezier(0.33, 1, 0.68, 1);
    transition-delay: 0.7s;
    
    &--active{
        transform: rotate(0deg) translateX(0px);
    }
}
.fondEtoile{
    transform: translateY(0%);
    transition: 1s cubic-bezier(.36,0,.67,-0.37);

    &--remove{
        transform: translateY(105%);
    }
}

//music
.btnM{
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: 0.5s cubic-bezier(.36,0,.67,-0.37);
    cursor: pointer;

    &--show{
        opacity: 1;
        visibility: visible;
    }
}
.hide{
    display: none;
}

.fondExpo{
    transform: translateY(0%);
    transition: 1s cubic-bezier(.36,0,.67,-0.37);

    &--remove{
        transform: translateY(105%);
    }
}

//anim tennis
@keyframes joueurG{
    from{
        transform: rotate(-2deg);
    } to{
        transform: rotate(0deg);
    }
}
@keyframes joueurD{
    0%{
        transform: rotate(3deg);
    } 100%{
        transform: rotate(0deg);
    }
}
@keyframes balle{
    from{
        transform: translateY(15px) rotate(-21deg);
    } to{
        transform:translateY(40px) rotate(17deg);
    }
}

//anim feu
@keyframes feu{
    from{
        transform: translateY(0);
    } to{
        transform: translateY(-0.5%);
    }
}

//anim scout
@keyframes bras{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(10deg);
    }
}