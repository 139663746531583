.body--burgerMenu{
    overflow: hidden;
}

.navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
 
    &__liens{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 0;
        padding: 0px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $c-background;
        z-index: -10;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s linear;
        font-size: 32px;
        text-align: left;

        &--hide{
            display: none;
        }
    }

    &__logo {
        svg:hover path{
            animation: dash 1.5s linear;
        }

        svg{
            width: 45px;
            height: 45px;
            margin: 0;
            align-self: center;

            path{ 
                stroke-dasharray: 14000;
                stroke-dashoffset: 0;
            }
        }
    }

    &__el{
        list-style: none;
        margin-left: 0px;
        font-family: 'owners-text', sans-serif;
        padding: 24px 54px;
        margin-left: 24px;

        a{
            transition: opacity 0.2s cubic-bezier(0.37, 0, 0.63, 1);
            opacity: 60%;
        }

        & a:hover{
            opacity: 100%;
        }

        & a:active{
            opacity: 10%;
        }

        &--active{
            background-image: url('../assets/images/illu/balleTennis100.svg');
            background-repeat: no-repeat;
            background-position-x: 0%;
            background-position-y: 50%;
            background-size: 20px;

            & a{
               opacity: 100%; 
            }  
        }

        &--work{
            background-image: url('../assets/images/icon/arrowSlider.svg');
            background-repeat: no-repeat;
            background-position-y: 53%;
            background-position-x: 50%;
            background-size: 36px;
            opacity: 60%;
            transition: opacity 0.3s cubic-bezier(0.37, 0, 0.63, 1);
            cursor: pointer;

            &:hover{
                opacity: 100%;
            }

            a {
                display: none;
            }
        }

        &--back{
            padding: 0;
            margin: 0;
            padding: 24px 54px;
            margin-left: 24px;
            margin-bottom: 24px;
        }
    }

    &__button{
        margin: 0px;
        padding: 0;
        font-family: 'owners-text', sans-serif;
        font-size: 32px;
        color: white;
        transition: opacity 0.3s cubic-bezier(0.37, 0, 0.63, 1);
        display: block;
    }
    &__back{
        color: white;
        background-color: none;
        text-decoration: underline;
        font-family: 'owners-text', sans-serif;
        font-size: 18px;
        padding: 0;

    }
}

.burgerMenu{
    text-indent: 100%;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    background-image: url('../assets/images/icon/burgerMenu.svg');
    transition: 0.5s;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 48px;
    width: 48px;
}

.navigation__liens--open .navigation__liens{
    opacity: 100;
    visibility: visible;
}

.header{
    mix-blend-mode: difference;

    &--burgerMenu{
        mix-blend-mode: normal;
    }
}

@keyframes dash {
    from {
      stroke-dashoffset: 14000;
    }
    to {
      stroke-dashoffset: 0;
    }
}

@media (max-width: 370px){
    .navigation{
        &__el{
            padding: 24px 48px;
            margin-left: 12px;

            &--active{
                background-position-x: 5%;
                background-size: 15px;
            }

            &--work{
                background-position-x: 60%;
                background-size: 36px;
            }
        }
    }
}

@media (min-width: 550px){
    .navigation{
        &__el{
            padding: 24px 48px;
            margin-left: 96px;

            &--active{
                background-position-x:0%;
                background-size: 15px;
            }

            &--work{
                background-position-x: 60%;
                background-size: 36px;
            }
        }
    }
}

@media (min-width: 760px){ //tablet
    .navigation{
        &__liens{
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: end;
            background-color: transparent;
            opacity: 100;
            font-size: 17px;
            z-index: 90;
            width: auto;
            height: auto;
            position: relative;
            visibility: visible;

            &--hide{
                display: none;
            }

            a{
                display: inline-block;
            }

            button{
                display: none;
            }
        }
    
        &__el{
            margin-left: 44px;
            padding: 0px 0px 12px 0px;
            cursor: pointer;

            &--active{
                background-position-y: 100%;
                background-size: 8px;
                background-position-x: 50%;
                transition: opacity 0.2s linear;
            }
        }

        &__logo{
            svg{
                width: 35px;
                height: 35px;
                margin: 0;
                align-self: center;
            }
        }

    }

    .burgerMenu{
        display: none;
    }
}

@media (min-width: 1110px){ //ordinateur
    .navigation{
        &__liens{
            font-size: 24px;
        }
    
        &__el{
            margin-left: 64px;
            padding: 0px 0px 14px 0px;
    
                &--active{
                    background-position-y: 100%;
                    background-size: 10px;
                    background-position-x: 50%;
                    transition: opacity 0.2s linear;
                }
        }

        &__logo{
            svg{
                width: 40px;
                height: 40px;
            }
        }
    }
}